import { ComplianceStepType, RampOrderSide, useGetNextStepCompliance } from '@ping/api';
import { FORM_B_STEPS } from '@ping/constants';
import { useIsUserLoggedIn } from '@ping/hooks';
import { isTokenValidSelector, useTokenStore } from '@ping/stores/token.store';
import {
  isUserActivityStatusActiveSelector,
  isUserAdminSelector,
  isUserComplianceReviewerSelector,
  isUserTradeDataViewerSelector,
  useUserInformationStore,
} from '@ping/stores/userInformation.store';
import { matches } from 'lodash-es';
import { useRouter } from 'next/router';
import { StaticRoute } from 'nextjs-routes';
import { isNotEmpty, isNotNil, switcher } from 'rambdax';
import { useCallback, useEffect, useState } from 'react';

/**
 * It checks if a user is authorized to access a page based on various flags and user information.
 * @param flags - The `flags` parameter is an object that contains boolean values indicating the
 * authorization requirements for the page.
 */
export const usePageAuthorization = (flags: {
  isPrivate: boolean;
  isAdmin: boolean;
  isComplianceReviewer: boolean;
  isTradeDataViewer: boolean;
}) => {
  const router = useRouter();
  const [isAuthorized, setAuthorization] = useState(false);

  const isUserAdmin = useUserInformationStore(isUserAdminSelector);
  const isUserComplianceReviewer = useUserInformationStore(isUserComplianceReviewerSelector);
  const isUserLoggedIn = useIsUserLoggedIn();
  const isTokenValid = useTokenStore(isTokenValidSelector);
  const isUserActivityStatusActive = useUserInformationStore(isUserActivityStatusActiveSelector);
  const isUserTradeDataViewer = useUserInformationStore(isUserTradeDataViewerSelector);
  const nextStep = useGetNextStepCompliance(undefined, {
    query: {
      enabled: isUserLoggedIn,
    },
  });

  const supervise = useCallback(
    flags => {
      //
      // NOTE: Due to the large number of condition parameters, it would require a lot of
      //       if else statements to handle all access scenarios. We used Pattern Matching
      //       to avoid that.
      return switcher<() => void>(flags)
        .is(matches({ pagePrivate: true, userLoggedIn: false }), () => router.push('/'))
        .is(matches({ pageAdmin: true, userAdmin: false }), () => router.push('/'))
        .is(matches({ userComplianceReviewer: true }), () => {
          const allowedRoutes = ['/admin/compliance', '/admin/transfers', '/admin/orders'];
          if (!allowedRoutes.includes(router.pathname)) {
            router.push('/admin/compliance');
          }
          setAuthorization(true);
        })
        .is(matches({ pageComplianceReviewer: true, userComplianceReviewer: false, userAdmin: false }), () =>
          router.push('/')
        )
        .is(matches({ pageTradeDataViewer: true, userTradeDataViewer: false, userAdmin: false }), () =>
          router.push('/')
        )
        .is(matches({ userTradeDataViewer: true }), () => {
          router.push('/admin/orders');
          setAuthorization(true);
        })
        .default(() => setAuthorization(true))();
    },
    [setAuthorization]
  );

  useEffect(() => {
    if (isOnRampResultCallback(router)) {
      router.push('/result/on-ramp');
      return;
    }

    supervise({
      pagePrivate: !!flags.isPrivate,
      pageAdmin: !!flags.isAdmin,
      pageComplianceReviewer: !!flags.isComplianceReviewer,
      userAdmin: !!isUserAdmin,
      userLoggedIn: !!isUserLoggedIn,
      tokenValid: !!isTokenValid,
      userComplianceReviewer: !!isUserComplianceReviewer,
      pageTradeDataViewer: !!flags.isTradeDataViewer,
      userTradeDataViewer: !!isUserTradeDataViewer,
    });
  }, [
    flags.isAdmin,
    isUserAdmin,
    flags.isComplianceReviewer,
    isUserComplianceReviewer,
    flags.isPrivate,
    isUserLoggedIn,
    isTokenValid,
    flags.isTradeDataViewer,
    isUserTradeDataViewer,
  ]);

  // TODO
  const comply = useCallback(
    step => {
      if (!step) {
        return;
      }
      if (router.pathname.endsWith('support')) {
        return;
      }

      if ((!isUserActivityStatusActive && FORM_B_STEPS.has(step)) || step === ComplianceStepType.FormA) {
        router.push('/user/portfolio');
      }
    },
    [nextStep.data]
  );

  useEffect(() => comply(nextStep.data), [nextStep.isLoading]);

  return isAuthorized;
};

/**
 * Determines if the current route is an OnRamp result callback.
 *
 * @param {StaticRoute<string>} route - The route object containing pathname and query parameters.
 */
function isOnRampResultCallback({ pathname, query }: StaticRoute<string>) {
  return (
    pathname.endsWith('ramp') &&
    query.side === RampOrderSide.Buy &&
    isNotEmpty(query.provider) &&
    isNotNil(query.provider)
  );
}
